.account-tab-list {
    display: flex;
    flex-direction: column;
}

.account-tab-list-item {
    color: #90A4AE;
    background-color: white;
    height: 40px;
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .atbli-title {
        margin-left: 12px;
        color: #546E7A !important;
    }
    &.active {
        background-color: #F5F5F5;
        .atbli-title, .atbli-icon {
            color: #0065FF !important;
        }
    }
}