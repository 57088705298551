.mfa-text {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.125rem;
    margin-top: 0.5rem;
}

.mfa-option {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.125rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 1.8rem;
}

.mfa-label {
    font-weight: bold;
    margin-left: 0.5rem;
    font-size:0.875rem;
}

.mfa-subtext {
    padding-left:1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 1.125rem;
    letter-spacing: 0.031rem;
    color: #4A4A4A;
}

.mfa-radio {
    height: 1rem;
    width: 1rem;
}

.mfa-need-help {
    padding-bottom:20px;

    // font-style: normal;
    // font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */

    letter-spacing: 0.5px;

    // color: #0065FF;


    text-align: center;
    line-height: 18px;
  
    button {
      letter-spacing: 0.26px;
      line-height: 16px;
      font-weight: 500;
      font-size: 13px;
      color: $hubsyncBlue;
    }
}

.mfa-btn-continue {
    margin-top:33px;
    margin-bottom:8px;
}

.mfa-token-duration {
    display: flex;
}

.mfa-token-duration-time {
    color: red;
    margin-left: 0.2rem;
}

