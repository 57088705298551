@import '~react-day-picker/lib/style.css';
@import '~styles/defaults/variables';

.DayPickerInput {
  display: block;
  height: 40px;
}

.datepicker-notify-icon-wrapper {
  vertical-align: middle;
  margin-left: 5px;
}

.DayPickerInput-Overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border-radius: 4px;
  padding-top: 10px;
  max-width: 250px;
  min-height: 350px;

  * {
    font-weight: 400;
  }
}

.DayPickerInput-OverlayWrapper[data-popper-placement="right-end"] {
  .DayPickerInput-Overlay {
    top: auto;
    bottom: 0;
  }
}

.datepicker-inner-wrapper {
  height: 357px;
  position: relative;
}

.DayPicker {
  line-height: initial;
  color: #172B4D;
  font-size: 13px;
  font-weight: 500;
}

.DayPicker-Month {
  border-spacing: 4px;
  border-collapse: separate;
}

.DayPicker-Caption {
  text-align: center;
  color: #172B4D;

  & > div {
    font-size: 1em;
    font-weight: 400;
  }
}

.DayPicker-Weekday {
  color: #90A4AE;
}

.DayPicker-NavButton {
  top: 0;
  background-image: none;
  color: #172B4D;
  margin: 2px 12px;
  font-size: 1.5em;
  &--prev {
    right: auto;
    left: 0;
  }
  &--prev:before {
    content: '\2190';
  }
  &--next {
    right: 0;
    text-align: right;
  }
  &--next:before {
    content: '\2192';
  }
}

.DayPicker-Month {
  margin: 6px 8px;
}

.DayPicker-Day {
  background-color: #fff;
  border-radius: 2px;
  color: #707070;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 13px;

  &--outside {
    background-color: transparent;
  }
  &--today:not(&--outside) {
    background-color: #E5EFFF;
    color: #0065FF;
  }
  &--selected:not(&--disabled):not(&--outside) {
    background-color: #0065FF;
    color: #fff;
  }
}

.DayPicker-Footer {
  text-align: center;
  padding: 0;
}

.DayPicker-TodayButton {
  background-color: #E9EDEF;
  color: #505F79;
  border-radius: 2px;
  padding: 3px 9px;
  font-weight: 500;
  font-size: 1em;
}

.ag-react-container {
  .DayPickerInput {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.DayPickerInput-OverlayWrapper{
  position: fixed;
  z-index: 1300;
}



.calendar-actions {
  justify-content: space-between;
  border-top: 1px $border-color solid;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  display: flex;
  height: 50px;
}

.datepicker-date-input-container {
  border: 1px solid $lightBlueGrey;
  margin: 2px 12px;
  height: 40px;
  border-radius: 2px;
  background-color: rgba(233,237,239,0.3);
  padding-left: 12px;
  padding-right: 9px;
  display: flex;
  justify-content: space-between;

  input {
    height: 38px;
    background: none;
    color: $slateTwo;
    font-size: 14px;
    width: 110px;
  }

  button {
    height: 28px;
    display: flex;
    align-self: center;
  }
}

.DayPicker-wrapper {
  margin-top: 2px;
  padding-bottom: 0;

  .DayPicker-Footer {
    display: none;
  }
}

.reminder-item {
  display: flex;
  line-height: 20px;
  border-bottom: 1px solid #E9EDEF;
  padding-bottom: 10px;
  padding-top: 10px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  .reminder-content {
    font-size: 13px;
    color: #253238;
  }

  .reminder-creator {
    color: #546E7A;
    font-size: 11px;
    margin-top: 4px;
  }

  .reminder-item-icon-container {
    margin-right: 5px;
    svg {
      color: #CFD8DC;
      height: 16px;
    }
  }

  .reminder-item-actions {
    display: flex;
    margin-top: 6px;

    button {
      margin-right: 20px;
      font-size: 13px;
    }
  }
}

.reminder-action-buttons.reminder-list-actions {
  justify-content: flex-end;
}

.DayPickerInput-Overlay.reminder-day-picker-container {
  position: static;
}

.shrink-date-picker {
  .DayPickerInput-Overlay {
    min-height: 250px;
  }
}

.reminder-select-deselect-container {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0 15px;
  text-align: right;

  .reminder-select-all,
  .reminder-deselect-all {
    margin-left: 10px;
    font-size: 11px;
    cursor: pointer;
  }

  .reminder-deselect-all {
    color: $brownishGrey;
    text-decoration: underline;
  }

  .reminder-select-all {
    color: $hubsyncBlue;
  }
}

.reminder-show-more-users-container {
  display: flex;

  .reminder-show-more-users {
    display: flex;
    padding: 4px 1px;
    font-size: 14px;
    padding-top: 5px;
    margin-left: 5px;
  }

  svg {
    width: 10px;
  }
}
