$max-width: 80rem;
$default-padding: 1.5rem 1rem;

// KANBAN SPECIFICS
$cards-width: 20rem;

// GLOBAL NAVIGATION
$menu-width: 17rem;
$animation-speed: 200ms;
$animation-ease: ease-in;

// FONTS
$icons: 'icons';

// FONT-WEIGHT
$bold: 700;
$regular: 400;
$light: 300;

// COLORS
$hubsync-blue: #0065FF;
$white: #ffffff;
$black: #000000;
$dark: #253238;
$bluescale: #0091EA, #009ccc, #f3f5f9, #47B1D5, #275d82;
$redscale: #d0021b, #e55757, #ffc0cb;
$yellowscale: #f5a623;
$grayscale: #8d99a8, #ededed, #d8d8d8, #64747f, #2e3541, #d8d8d8, #979797, #49505c, #e3e5e9, #4A4A4A;
$input-color: #A0A0A0;
$input-label-color: #212121;
$title-color: #4A4A4A;
$title-color-welcome: #212121;
$card-label-color: #212121;
$athensGray: #ebecf0;
$alto: #e0e0e0;
$border-color: #E9EDEF;
$offYellow: #FDD835;
$primary-hover: #e8f2ff;

$text-darkblue: #546e7a;
$background-color: #F9F9FB;

$paleGrayTwo: #f8f9fa;
$blackTransparent: rgba(0, 0, 0, 0.12);

// ICON BACKGROUND
$iconBlue: #8bd3ef;

$tomato: #e53935;

$athensGrey: #F4F5F7;
$slateTwo: #505f79;
$slate: #546E7A;

$lightBlueGrey: #cfd8dc;

$greyTransparent: rgba(233, 237, 239, 0.3);

$brownishGrey: #707070;


$hubsyncBlue: #0065FF;
$darkBlue: #003497;
$skyBlue: #E6F0FF;
