@import "~styles/defaults/variables";

.wrapper {
  text-align: center;
};

.rowTools {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
};

.insertRowAboveButton {
  background-color: $lightBlueGrey;
  color: $white;
  width: 14px;
  height: 14px;
  display: inline-flex;
  margin-top: -1px;
  align-items: center;
  border-radius: 0;
  justify-content: center;
};

.insertRowBelowButton {
  background-color: $lightBlueGrey;
  color: $white;
  width: 14px;
  height: 14px;
  display: inline-flex;
  margin-bottom: -1px;
  align-items: center;
  border-radius: 0;
  justify-content: center;
};

//.noCreatePermission {
//  & .insertRowAboveButton {
//    visibility: hidden;
//  };
//  & .insertRowBelowButton {
//    visibility: hidden;
//  };
//}
