@import '~styles/defaults/variables';

.account-chip-account_wrapper {
  padding: 4px 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.ag-cell {
  .account-chip-account_wrapper {
    padding: 0;
    display: inline-flex;
    align-items: center;
    margin-right: 6px;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
    height: auto;
  }
}

.account-chip-account_name {
    color: $dark;
    font-family: Roboto;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 16px;
    margin-left: 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    color: #4A4A4A;
}

.account-chip-icon_wrapper {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  svg {
    margin: 0 2px;
  }
}
