.btn {
  font-size: 1rem;
  line-height: 1.875rem;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.2);
  background-color: var(--buttonBackgroundColor, rgba(nth($bluescale, 2), 0.5));
  padding: 0 0.625rem;
  color: var(--buttonTextColor, $black);

  &:before {
    padding-right: 0.625rem;
  }

  &:hover {
    color: $white;
    background-color: rgba(darken(nth($bluescale, 2), 0%), 1);
  }

  &.strong {
    background-color: rgba(darken(nth($bluescale, 2), 0%), 0.75);

    &:hover {
      background-color: rgba(nth($bluescale, 2), 1);
    }
  }

  &.invert {
    background-color: rgba(darken(nth($bluescale, 2), 0%), 1);
    color: $white;

    &:hover {
      color: $black;
      background-color: rgba(nth($bluescale, 2), 0.5);
    }
  }
}
