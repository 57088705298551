@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-richtexteditor/styles/material.css';

$modal-field-color: rgba(233, 237, 239, 0.3);

/** Cells in the grid with rich text editor */
#root {
  // TODO: Resolve specificity issues to avoid using !important
  /** General styles for both, Single line and Multi line */
  .e-richtexteditor {
    &:not(.e-rte-toolbar-enabled) {
      border: none;
    }

    .e-rte-content {
      margin-top: 0px;
      background-color: transparent;
      overflow: hidden;
      z-index: auto !important;

      .e-content {
        margin: 0 !important;
        padding: 0 !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        min-height: unset !important;
        display: flex;
        align-items: center;
        z-index: auto !important;

        p {
          min-width: 2px;
          line-height: 1.25rem !important;
          margin: 0 0.125rem 0 0.125rem !important;
          min-width: 2px;
        }
      }
    }
  }

  /* Reset Styles for richtexteditor on messages */
  .messages-richtexteditor {
    .e-richtexteditor {
      .e-resize-handle {
        display: none;
      }
      .e-content {
        margin: unset!important;
        padding: 16px 36px 16px 16px !important;
        text-overflow: unset !important;
        overflow: scroll !important;
        display: block;
        align-items: unset;
        ol, ul{
          padding-inline-start: 16px;
        }
        ol {
          list-style: decimal;
        }
        ul {
          list-style: disc;
        }
      }
    }
  }

  /** Depending row sizes */
  .single-short-overflow, .multi-short-overflow {
    &.e-richtexteditor {
      .e-rte-content {
        .e-content {
          p {
            min-width: 2px;
            -webkit-line-clamp: 1 !important;
          }
        }
      }
    }
  }

  .single-medium-overflow, .multi-medium-overflow {
    height: auto !important;
    &.e-richtexteditor {
      .e-rte-content {
        .e-content {
          display: -webkit-box !important;
          p {
            min-width: 2px;
            width: 100% !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            line-height: normal !important;
            white-space: pre-wrap !important;
            overflow-wrap: break-word !important;
            display: -webkit-box !important;
            -webkit-box-orient: vertical !important;
            -webkit-line-clamp: 3 !important;
            height: auto !important;
            margin: auto !important;
          }
        }
      }
    }
  }

  .single-tall-overflow, .multi-tall-overflow {
    &.e-richtexteditor {
      .e-rte-content {
        .e-content {
          display: -webkit-box !important;
          p {
            min-width: 2px;
            width: 100% !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            line-height: normal !important;
            white-space: pre-wrap !important;
            overflow-wrap: break-word !important;
            display: -webkit-box !important;
            -webkit-box-orient: vertical !important;
            -webkit-line-clamp: 4 !important;
            height: auto !important;
          }
        }
      }
    }
  }

  /** Only for Single line */
  .column-singlelineoftext {
    .e-content {
      p {
        width: 100% !important;
        overflow: hidden !important;
        white-space: nowrap !important;
      }
    }

    // Only when it is not editing
    &.ag-cell-not-inline-editing {
      .e-content {
        p {
          text-overflow: ellipsis !important;
        }
      }
    }
  }

  /** Only for Multi line */
  .rte-multi-text-grid {
    height: 100% !important;

    &.multi-medium-overflow {
      padding-top: 6px;
    }

    &.multi-tall-overflow {
      padding-top: 12px;
    }

    .e-rte-content {
      border-top: none !important;
      border-bottom: none !important;
      .e-content {
        white-space: break-spaces !important;
        word-break: break-word !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
        justify-content: center;
      }
    }
  }

  /** Popup Multi line editor */
  .ag-popup-editor {
    min-width: auto !important;

    .rte-multi-text-grid-editor .e-rte-content .e-content {
      overflow-y: auto !important;
    }

    .ag-react-container {
      align-items: flex-start;

      .e-richtexteditor {
        .e-rte-content {
          .e-content {
            padding: 12px 6px 12px 12px !important;
          }
        }
      }
    }
  }

  .multi-short-overflow {
    &.e-richtexteditor {
      .e-rte-content {
        .e-content {
          justify-content: start;
          p {
            min-width: 2px;
            padding-top: 5px;
            overflow: hidden !important;
            white-space: nowrap !important;
          }
          p:nth-child(n+2) {
            display: none;
          }
        }
      }
    }
  }

  .rte-multi-text-grid-editor {
    max-width: 350px !important;
    min-width: 200px !important;
    min-height: 180px !important;
    max-height: 400px !important;
    .e-rte-content {
      .e-content {
        white-space: break-spaces !important;
        word-break: break-word !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start !important;
      }
    }
  }
  /** Hyerlink modal */
  .e-rte-link-dialog {
    .e-checkbox-wrapper {
      visibility: hidden;
    }

    .e-btn {
      &.e-primary {
        color: #0065FF;

        &:hover, &:active, &:focus {
          background-color: rgba(0, 109, 255, 0.04);
          border-color: transparent;
          color: #0065FF;
        }
      }
    }

    .e-input:focus:not(.e-success):not(.e-warning):not(.e-error) {
      border-color: #0065FF;
      border-bottom-style: solid;
    }
  }
}

/** Multiline Text Popup in grid for readers */
#MultiLineofText-Popover {
  .e-richtexteditor {
    &.rte-multi-text-grid-popover.e-rte-readonly {
      border: none;
    }

    .e-content {
      padding: 0;
      overflow: hidden;
    }
  }
}

/** Fields in Modal(detailed view) with rich text editor **/
.nodeView-modal {
  .e-richtexteditor .e-rte-content .e-content,
  .e-richtexteditor .e-source-content .e-content {
    min-height: 0;
  }

  /** Multi line text */
  .rte-multi-text-detailed-editor {
    border: none;

    .e-rte-content {
      background-color: $modal-field-color;

      padding: 14px 0 10px 0 !important;

      .e-content {
        height: 105px;
        padding: 0 16px;
        overflow-x: hidden;
        word-break: break-word;
        text-align: inherit;
      }
    }
  }

  /** Single line text */
  .rte-single-text-detailed {
    background-color: $modal-field-color;
    padding: 0 16px;

    &.e-focused {
      border: 1px solid #0065ff;
    }

    .e-rte-content {
      background-color: transparent;
      height: 44px !important;
      margin-top: 0px;
      overflow: hidden;

      .e-content {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        position: relative;
        flex-wrap: nowrap;
        min-width: 236px;
        width: max-content;
        padding: 16px 0;

        p {
          min-width: 100%;
        }
      }
    }
  }

  /** Hyperlink popup inside modal */
  .e-dialog.e-popup.e-popup-open.e-rte-link-dialog {
    left: 40% !important;
    top: 25% !important;

  }
}


// /** OVERRIDING INLINE STYLES */
// /** There is no other way but using `!important` */
.e-popup.e-control.e-rte-pop.e-popup-open[style] {
  max-width: unset !important;
}

.e-rte-content {
  padding: none !important;
}

.e-content {
  em {
    font: revert;
    font-style: italic !important;
  }
}

/** Hyperlink popup */
.e-dialog.e-popup.e-popup-open.e-rte-link-dialog {
  height: 344px !important;
  left: 0 !important;
  min-height: 344px !important;
  position: fixed !important;
  top: 0 !important;
  width: 240px !important;
}

/* Reset Styles for richtexteditor on messages */
.messages-richtexteditor {
  .e-richtexteditor {
    .e-resize-handle {
      display: none;
    }
    .e-rte-character-count {
      margin-right: 16px;
    }
    .e-content {
      margin: unset!important;
      padding: 16px 46px 16px 16px!important;
      text-overflow: unset !important;
      overflow: unset !important;
      display: block;
      align-items: unset;
      ol, ul{
        padding-inline-start: 16px;
      }
      ol {
        list-style: decimal;
      }
      ul {
        list-style: disc;
      }
    }
  }
}
