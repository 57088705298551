@import "~styles/defaults/variables";

.boolean-renderer {
  &--wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &--checkbox-checked{
      width: 15px;
      height: 15px;
      color: $hubsync-blue;
    }

    &--checkbox-unchecked{
      width: 15px;
      height: 15px;
      color: $slateTwo;
    }
  }
}
