@import '../../../node_modules/@hubsync/file-manager/dist/styles.css';

.file-manager-wrapper {
  margin: 0 -16px;
  height: 90%;
}

.file-manager-header-wrapper {
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.file-manager-header-title{
  margin-left: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}
