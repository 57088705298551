@import "~styles/defaults/variables";

.table__header__tools {
  &__container {
    border-bottom: 1px solid #e0e0e0;
    background-color: $white;
    height: 48px;
    padding: 0 16px;
  }

  &__list {
    // DISPLAY MENU OPTIONS
    &.display__menu {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }

  &__item {
    font-size: 1rem;
    margin-right: 8px;
  }

  // DISPLAY MENU OPTIONS
  &__menu__container {
    margin-left: auto;
  }

  // OPTIONS GLOBAL STYLES
  &__option {
    justify-content: space-between;
    color: #253238;

    &__scrollable__container {
      max-height: 64vh;
      overflow: auto;
      @media screen and (max-width: 720px) {
          max-height: 72vh;
      }
    }

    &.enable-hover {
      cursor: pointer;
      margin-bottom: 0.3125rem;
      padding: 0.25rem 1rem;
      margin: 0 -10px;

      &:hover {
        background: rgba(#00aeef, 0.05);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__container {
      position: relative;
    }

    &__btn {
      font-size: 1rem;
      line-height: 1.875rem;
      border-radius: 0.25rem;
      box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.2);
      background: $white;
      position: relative;
      padding-left: 1.875rem;
      @media screen and (max-width: 791px) {
        padding-left: 1.575rem;
      }

      .open &,
      &:hover {
        background: rgba($black, 0.1);
      }

      &:before {
        display: block;
        font-size: 0.875rem;
        position: absolute;
        top: 50%;
        left: 0.3125rem;
        width: 1.25rem;
        height: 1.25rem;
        line-height: 1.25rem;
        text-align: center;
        transform: translateY(-50%);
      }
    }

    &__popup {
      position: absolute;
      top: 100%;
      z-index: 121;
      background-color: $white;
      padding: 16px 0 16px 0;
      border-radius: 4px;
      box-shadow: 0 4px 8px 0 #eae9e9, 0 2px 4px 0 rgba(150, 150, 150, 0.3);
    }

    &__dropdown__container {
      padding-top: 0.625rem;
    }
  }

  // LOW NAV
  &__low__nav {
    &__list {
      padding-top: 0.625rem;
      display: flex;
      flex-wrap: nowrap;
    }

    &__item {
      flex-grow: 2;

      &:first-child {
        padding-right: 0.3125rem;
      }

      &:last-child {
        padding-left: 0.3125rem;
      }
    }

    &__btn {
      @extend .table__header__tools__option__btn;
      white-space: nowrap;
      padding-left: 0.4375rem;
      text-align: center;
      width: 100%;

      &:before {
        display: inline-block;
        position: static;
        padding: 0 0.3125rem 0 0;
        transform: none;
      }

      .open & {
        background: $white;
      }

      &.active,
      &:hover {
        background: rgba($black, 0.1);
      }
    }
  }

}

.table__header__tools__option {
  padding-bottom: 0.625rem;
  line-height: 1.25rem;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;

  &:last-child {
    padding-bottom: 0;
  }

  &__input {
    border: 0.0625rem solid nth($grayscale, 1);
    display: block;
    width: 100%;
    padding: 0 0.3125rem;
    font-size: 0.875rem;
    height: 1.875rem;
    line-height: 1.875rem;

    &__container {
      padding-bottom: 0.625rem;
    }
  }

  &__list {
    background: nth($grayscale, 3);
  }

  &__item {
    display: flex;
    z-index: 200;
    background: $white;
    position: relative;
    padding-left: 1.125rem;

    .table__header__tools__option {
      flex-grow: 2;
    }
  }

  &__handler {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0 0.3125rem;
    line-height: 1.6rem;
    cursor: move;

    &:hover {
      background: nth($grayscale, 2);
    }
  }

  &__title__note {
    text-align: center;
    font-size: 0.8125rem;
    color: nth($grayscale, 1);
  }

  &__grouped__entry {
    font-size: 13px;
  }

  &__grouped__title {
    padding: 0 0.625rem 0 0;

    i {
      padding-right: .4rem;
    }
  }

  &__grouped__sort {
    padding-left: 1.25rem;
    text-align: right;
    flex-grow: 2;
  }

  &__remove__btn {
    cursor: pointer;

    &:before {
      font-size: 0.625rem;
      padding-right: 0.625rem;
    }
  }
}

.chart-icon-wrapper {
  color: $slateTwo;
}
