@import "../../styles/defaults/variables.scss";
.mui-popover {
  overflow: visible;

  > div {
    overflow: visible;
  }

}
.popover {

  &__option {
    position: relative;
  }

  &__btn {
    padding: 0.3125rem;
    margin-top: 0.3125rem;
    white-space: nowrap;
    //color: $white;
    border-radius: 0.25rem;

    &:before {
      padding-right: 0.3125rem;
    }
  }

  &__popup {
    padding: 0 0.3125rem 0.3125rem 0.3125rem;
    position: absolute;
    top: 100%;
    right: 0;
    background: nth($bluescale, 4);
  }
}
