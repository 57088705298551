.activity-search {
    background-color: white;

  input {
    background-color: white;
  }
}

.activity-filter-container {

  justify-content: flex-end;
  flex-direction: row;
  display: flex;

  > * {
    margin-left: 10px !important;
    width: auto;
  }

  * {
    white-space: nowrap;
  }
}

.file_title {
  font-weight: 500;
}
