.attachment-renderer-wrapper {
  max-width: 100%;

  .attachment-renderer-inner-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .attachment-renderer-hidden-options {
    opacity: 0;
    height: 1px;
    display: flex;
  }
}

.group-attachment-wrapper {
  svg {
    margin-top: 4px;
    margin-left: -3px;
  }

  span {
    display: inline-block;
    vertical-align: top;
    margin-top: 4px;
    margin-left: 6px;
    font-size: 14px;
  }
}