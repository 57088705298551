.account-avatar {
  box-sizing: border-box;
  border: 1px solid #CFD8DC;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  &.small {
    height: 16px;
    width: 16px;
  }
  &.medium {
    height: 30px;
    width: 30px;
  }
  &.large {
    height: 64px;
    width: 64px;
    .account-avatar-ic {
      font-family: Roboto;
      font-size: 23.47px;
      letter-spacing: 0.5px;
      line-height: 28px;
      text-align: center;
    }
  }
  &.extra-large {
    height: 96px;
    width: 96px;
    .account-avatar-ic {
      font-family: Roboto;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0.2px;
      line-height: 40px;
      text-align: center;
    }
  }
}
