.aggregation-status-container {
    display: inline;
    border-radius: 2px;
    background-color: #E9EDEF;
    padding: 5px;
    &__item {
        display: inline;
        margin: 0 15px;
        &__title {
            color: #505F79;
            font-family: Roboto;
            font-size: 10px;
            letter-spacing: 0.18px;
            line-height: 10px;
            text-transform: uppercase;
        }
        &__value {
            color: #172B4D;
            font-family: Roboto;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.18px;
            line-height: 10px;
        }
    }
}
