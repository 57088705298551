.editor_personalized {
  box-sizing: border-box;
  border: 1px solid #ccc;
  cursor: text;
  border-radius: 2px;
  line-height: 20px;
  background: #fefefe;
  font-size: 14px;
  background-color: rgba(233, 237, 239, 0.3);
}

.editor_personalized:focus-within {
  outline:none;
  border: 1px solid rgb(0, 101, 255);
}

.editor_personalized_single {
  box-sizing: border-box;
  border: 1px solid #ccc;
  cursor: text;
  padding: 12px 8px 12px 8px;
  border-radius: 2px;
  background: #fefefe;
  font-size: 14px;
  line-height: 20px;
  background-color: rgba(233, 237, 239, 0.3);

  &.locked {
    padding-right: 24px;
  }
}

.editor_personalized_single:focus-within {
  outline:none;
  border: 1px solid rgb(0, 101, 255);
}

.editor :global(.public-DraftEditor-content) {
  min-height: 130px;
}

.editor_personalized > div.DraftEditor-root {
  height: 80px;
  overflow-y: auto;
}

.editor_personalized_single > .DraftEditor-root {
  height: 18px;
  overflow-y: hidden !important;
}

div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
  width: 100%;
}

.editor_personalized_single >.DraftEditor-root >.DraftEditor-editorContainer >.public-DraftEditor-content > div > div >.public-DraftStyleDefault-block  {
  white-space:nowrap !important;
  overflow-x: hidden !important;
}

.lxvs42t {
  text-decoration: underline;
  color: rgb(0, 101, 255) !important;
}
