@import '../defaults/variables';

.auth-container {
  background-image: url('../../assets/images/auth/hubsync-login-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  justify-content: center;
}

.auth-containerbakertilly {
  background-image: url('../../assets/images/auth/hubsync-login-backgroundbakertilly.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  justify-content: center;
 
}


#root > div.auth-containerbakertilly > div > div.auth-form-content > div > div > div > div:nth-child(2) > div:nth-child(2) > div > span {
  color: #D1EC51 !important;
}

#root > div.auth-containerbakertilly > div > div.auth-form-content > div > div > div > div:nth-child(2) > div:nth-child(1) > div > div > span {
  color: #D1EC51 !important;
}

.auth-logo {
  text-align: center;
  padding-top: 32px;
  padding-bottom: 16px;

  img {
    width: 128px;
  }
}

.expire-logo {
  text-align: center;

  img {
    width: 221px;
  }
}

.auth-password-expired {
  .password-expired-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      color: #212121;
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35px;
  }
  .password-expired-message {
    margin: 35px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      color: #212121;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-align: center;
    }
    .email {
      color: #212121;
      font-family: Roboto;
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-align: center;
      font-weight: bold;
    }
  }
  .password-expired-button {
    margin: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      background-color: #0065FF;
      justify-content: center;
      border-radius: 2px;
      padding: 7px 10px;
      height: 40px;
      cursor: pointer;
      color: #fff;
    }
  }

  .password-expired-buttonbakertilly {
    margin: 32px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      background-color: #D1EC51 !important;
      justify-content: center;
      border-radius: 2px;
      padding: 7px 10px;
      height: 40px;
      cursor: pointer;
      color: black;
    }

    button:hover {
      background-color: #b0cf14 !important;
      cursor: pointer;
      color: black !important;
    }
  }
}

.auth-form-container {
  box-shadow: 0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2);
  max-width: 414px;
  width: 414px;
  grid-column: 5/9;
  margin: auto;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;

  &.show-overflow{
    overflow: visible;
  }

  form {
    margin: 0 40px;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: transparent;
      -webkit-text-fill-color: $card-label-color;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    input {
      background-color: transparent;
      &[type="password"] {
        letter-spacing: 4px;
      }
      &::placeholder {
        letter-spacing: 1px;
      }
    }
    .auth-options{
      display: flex;
      justify-content: space-between;
    }
  }

}

.auth-action-container {
  margin-bottom: 24px;
  margin-top: 24px;
}

.auth-action-btn {
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;

  button {
    background-color: $hubsync-blue;
    justify-content: center;
    border-radius: 2px;
    padding: 7px 10px;
    height: 40px;
    font-size: 13px;
    cursor: pointer;
    color: #fff;

    &[disabled] {
      background-color: #96BBF8;
    }
  }
}

.auth-action-btnbakertilly {
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;

  button {
    background-color: #D1EC51 !important;
    justify-content: center;
    border-radius: 2px;
    padding: 7px 10px;
    height: 40px;
    font-size: 13px;
    cursor: pointer;
    color: black;

    &[disabled] {
      background-color: #97979780 !important;
      color: white !important;
    }
  }

  button:hover {
    background-color: #b0cf14 !important;
    cursor: pointer;
    color: black !important;
  }
}

.terms-and-conditions-headerbakertilly {
  background-color: #D1EC51 !important;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
  border-radius: 0.25rem 0.25rem 0 0;
}

.forgot-password-link {
  justify-content: flex-start;
  display: inline-flex;

  span {
    color: $hubsync-blue;
    letter-spacing: 0.26px;
    line-height: 16px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
  }
}

.help-option-link {
  span {
    color: $hubsync-blue;
    letter-spacing: 0.26px;
    line-height: 16px;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
  }

  &--align-right{
    margin-left: auto;
  }
}

.auth-input-container {
  margin: 24px 0 12px;

  label {
    svg {
      margin-right: 5px;
      height: 16px;
    }
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    justify-content: flex-start;
    color: $input-label-color;
    letter-spacing: 0.25px;
    align-items: center;
    margin-bottom: 6px;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    height: 16px;
  }

  .auth-input-text-field{
    background-color: rgba(233, 237, 239, 0.3);

    input{
      background-color: transparent;
    }
  }

  .auth-input-start-icon{
    margin-left: 8px;
  }

  &.two-columns {
    justify-content: space-between;
    flex-direction: row;
    display: flex;

    > * {
      margin: 0 10px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.auth-input-error-container {
  display: flex;
  align-items: center;

  .auth-input-error {
    font-weight: bold;
    padding-left: 5px;
  }
}


.auth-social-btn-container {
  height: 40px;
  display: flex;
  cursor: pointer;
  overflow: hidden;
  margin: 10px 20px;
  border-radius: 3px;

  .auth-social-icon {
    width: 42px;
  }

  .auth-social-label {
    color: #fff;
    font-size: 10px;
    font-weight: 600;
    margin-left: 10px;
    align-self: center;
    display: inline-block;
    text-transform: uppercase;
  }
}

.auth-social-btn-container.google {
  margin-top: 20px;
  background-color: #4285f4;
  .auth-social-icon {
    width: 40px;
    height: 36px;
    margin-top: 2px;
    margin-left: 2px;
    background-image: url('../../icons/google_icon.svg');
    background-color: #fff;
  }
}

.auth-social-btn-container.microsoft {
  margin-top: 10px;
  background-color: #00a1f1;
  .auth-social-icon {
    height: 40px;
    background-image: url('../../icons/microsoft_icon.svg');
    background-color: rgba(0, 0, 0, .3);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
  }
}

.auth-social-btn-container.linkedin {
  margin-top: 10px;
  background-color: #0077b5;
  .auth-social-icon {
    height: 40px;
    background-image: url('../../icons/linkedin_icon.svg');
    background-color: rgba(0, 0, 0, .3);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
  }
}

.auth-type-separator {
  font-size: 13px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  margin: 20px 0;
}

.auth-label, .auth-log-in-label {
  display: block;
  font-size: 13px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}

.auth-log-in-label {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 20px;
}

.auth-sign-in-btn {
  border-top: 1px $border-color solid;
  text-align: center;
  line-height: 48px;

  button {
    letter-spacing: 0.26px;
    line-height: 16px;
    font-weight: 500;
    font-size: 13px;
    color: $hubsyncBlue;
  }
}

.auth-error-message {
  letter-spacing: 0.24px;
  margin-bottom: 10px;
  line-height: 16px;
  font-weight: 500;
  font-size: 12px;
  color: #E8384F;

  span {
    display: inline-block;
    margin-right: 5px;
    line-height: 0;
    height: 10px;
    width: 10px;
  }
}

.auth-welcome-container {
  text-align: center;
  color: $title-color;

  h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    padding: 0;
    margin: 0 0 8px;
    color: $title-color-welcome;
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 13px;
    margin: 0px -20px 5px;
    letter-spacing: 0.5px;
    padding: 0;

    b{
      font-weight: 700;
    }
  }

  &--login{
    > p{
      max-width: 100%;
      margin: 0 40px 0 40px;
    }

    .welcome-tenant-optional-text{
      color: #A80000;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.auth-footer-container {
  text-align: center;
  padding-bottom: 32px;
  color: #4A4A4A;

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    margin: 0 0 5px;
    letter-spacing: 0.5px;
    padding: 0;

    a {
      color: $hubsync-blue;
      text-decoration: underline;
    }
  }

  &--temporary{
    p{
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.auth-code-sent {
  text-align: center;
  margin-bottom: 45px;

  svg {
    color: $hubsyncBlue;
    margin: 20px 0 45px;
  }

  p {
    display: block;
    font-size: 0.85rem;
    line-height: 21px;
    margin: 0;
  }

  span {
    color: $title-color;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
  }
}

.show-password-toggle {
  padding: 8px 8px 8px 0;
  background: rgba(233, 237, 239, 0.3);
}

.password-strength {
  padding: 15px;

  p {
    letter-spacing: 0.22px;
    margin-bottom: 10px;
    color: $title-color;
    line-height: 14px;
    font-size: 11px;
    display: block;
  }

  ul, li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    letter-spacing: 0.22px;
    line-height: 20px;
    color: $slateTwo;
    font-size: 11px;

    span {
      vertical-align: middle;
      display: inline-block;
      margin-right: 5px;
      margin-top: -2px;
      line-height: 0;
      height: 10px;
      width: 10px;

      &.valid {
        color: #33B679;
      }

      &:not(.valid) {
        svg {
          display: none;
        }

        &:before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          border: 1px $lightBlueGrey solid;
        }
      }
    }
  }
}

.auth-error-container {
  display: flex;
  > span {
    margin-right: 10px;
  }
}

.request-new-password-link {
  color: $hubsync-blue;
  cursor: pointer;
  text-decoration: underline;
}

.request-new-password-link.disabled {
  color: #939393;
}

.auth-code-send-link {
  text-align: center;
  border-top: 1px solid $border-color;
  padding-top: 16px;
  padding-bottom: 16px;
  span {
    color: $hubsync-blue;
    cursor: pointer;
    font-size: 13px;
  }
}

.auth-input-container-hide-input-error {
  #password-helper-text, #firstName-helper-text, #lastName-helper-text, #confirmPassword-helper-text, #email-helper-text {
    display: none;
  }
}

.auth-input-container {
  .auth-email-icon {
    height: 13px;
  }
}

hr {
  border-top: 1px solid $border-color;
}

.auth-reset-password-footer{
  margin: 0 40px;
  font-size: 12px;
  color: #4A4A4A;
  margin-bottom: 32px;
  text-align: center;

  a{
    color: $hubsync-blue;

    &:hover{
      text-decoration: underline;
    }
  }
}

.auth-form-mfa-container {
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.2);
  max-width: 414px;
  width: 414px;
  grid-column: 5/9;
  margin: auto;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;

  &.show-overflow{
    overflow: visible;
  }

  form {
    margin: 0 40px;
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: transparent;
      -webkit-text-fill-color: $card-label-color;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    input {  
      background-color: transparent;
      &[type="password"] {
        letter-spacing: 4px;
      }
      &::placeholder {
        letter-spacing: 1px;
      }
    }
  }
}
