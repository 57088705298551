.selection-dependant-btn-download {
  svg {
    width: 15px;
    margin-right: 4px;
  }
}

.selection-dependant-btn-copy-move {
  svg {
    margin-right: 5px;
  }
}

.selection-dependant-btn-rename {
  svg {
    width: 13px;
    margin-right: 6px;
  }
}

.selection-dependant-btn-delete {
  svg {
    width: 14px;
    margin-right: 6px;
  }
}

