@import '../../styles/defaults/variables.scss';

$password-bg-default: #d9d9d9;
$password-bg-fair: #f0840d;
$password-bg-strong: #107c10;
$icon-close: #727272;

.password-container {
  padding: 0;

  &.focused{
    .password__input > div{
      border: 1px solid $hubsyncBlue;
    }
  }

  &.reset {
    display: flex;
    align-items: center;
    .reset-btn {
      position: relative;
      color: $hubsyncBlue;
      background: $primary-hover;
      padding: 8px;
      font-size: 13px;
      line-height: 14px;
      border-radius: 2px;
      letter-spacing: 0.2px;
      white-space: nowrap;
      margin-left: 16px;
      height: 30px;
    }
  }

  input {
    height: 20px;
    padding: 10px 0px;
    font-size: 14px;
    
  }

  .password__input > div{
    border: 1px solid #cfd8dc;
    border-radius: 2px;
    background-color: rgba(233, 237, 239, 0.3);
  }

  li{
    margin-left: 0 !important;
    margin-bottom: 13px;
    line-height: 1;
    display: flex;
    align-items: center;
    color: nth($grayscale, 10);
    font-weight: 300;

    &:last-child{
      margin-bottom: 0;
    }
  }

  .strength {
    width: 221px;
    position: absolute;
    height: auto;
    margin-top: 8px;
    line-height: 20px;
    background-color: white;
    top: -70px;
    right: -230px;
    z-index: 1;
    padding: 16px 19px;
    border-radius: 2px;
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.14), 0px 5px 14px 2px rgba(0, 0, 0, 0.12),
      0px 5px 5px -5px rgba(0, 0, 0, 0.2);
    &::before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      left: -10px;
      top: 70px;
      border-right: 10px solid white;
    }

    &-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: $input-label-color;
      display: inline-block;
      margin-bottom: 13px;
    }
  }

  .password--valid {
    font-size: 11px;
    &:before {
      content: '\ea84';
      font-family: 'e-icons';
      display: inline-block;
      vertical-align: top;
      color: $password-bg-strong;
      font-size: 12px;
      margin-right: 5px;
    }
  }

  .password--invalid {
    font-size: 11px;
    &:before {
      color: $icon-close;
      content: '\e671';
      font-family: 'e-icons';
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .password--error {
    font-size: 11px;
    &:before {
      color: nth($redscale, 1);
      content: '\e7e9';
      font-family: 'e-icons';
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      margin-right: 5px;
    }
  }
}
