.column-singlechoice,
.column-multiplechoice,
.column-account {
  .dropdown-button,
  .singlechoice-to-cell-editor {
    display: none;
  }
  
  &.ag-cell-focus {
    .singlechoice-to-cell-editor {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }

    .dropdown-button {
      margin-left: auto;
    }
  }
}

.grid-choice-item-bullet {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 6px;
}

.single-choice-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
}

.multiple-choice-item-medium {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: wrap;
  flex-flow: row wrap;
  gap: 0.25rem;
} 

.multiple-choice-item-tall {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: wrap;
  flex-flow: row wrap;
  gap: 0.25rem;
}

.cell-attachment-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}
.cell-attachment-item.row-height-taller{
  flex-flow: row wrap;
} 


.cell-attachment-highlighted-item {
  filter: brightness(1);
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
}

.ag-row-group .ag-fill-handle {
  display: none;
}

.ag-theme-material .ag-ltr .ag-group-value .ag-group-child-count {
  margin-left: 5px;
}

.ag-theme-material .ag-group-value .multi-short-overflow {
  padding-bottom: 4px;
}

.group-expand-container {
  position: relative;

  > button {
    box-shadow: none !important;
    border-radius: 100%;
    margin-left: 5px;
    background: none;
    outline: none;
    border: none;
    height: 24px;
    width: 24px;

    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1;
    font-size: 0;

    &:hover {
      background: $athensGrey;
    }
  }
}

.expand-options-area {
  padding: 14px 0;
  background-color: #FFFFFF;
  box-shadow: 0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2);

  li {
    padding: 0 12px;
    align-items: center;
    line-height: 26px;
    font-size: 13px;
    cursor: pointer;
    display: flex;

    &:hover {
      background: $athensGrey;
    }

    svg {
      margin-right: 5px;
      width: 24px;
    }
  }
}
