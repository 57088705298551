@import "~styles/defaults/variables";

.button-renderer, .button-editor  {
  &--wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    &--button{
      display: block;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
      padding: 6px 10px;
    }
  }
}

.button-editor--wrapper {
  justify-content: 'flex-start',
}

.button-renderer--wrapper {
  justify-content: center;
}
