@import "~styles/defaults/variables";

.calendar-notifier {
  padding: 5px 0;
  border-top: 1px $border-color solid;

  .calendar-notifier-switch {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
    display: flex;
    height: 40px;
    flex: 1;

    .calendar-notifier-label {
      margin-top: 5px;
      > span {
        text-transform: uppercase;
        letter-spacing: 0.22px;
        line-height: 13px;
        font-weight: 400;
        font-size: 11px;
        color: $card-label-color;
        vertical-align: top;
        padding-top: 2px;
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .calendar-notifier-scheduler {
    justify-content: space-between;
    border-top: 1px $border-color solid;
    flex-direction: row;
    padding: 4px 15px;
    display: flex;

    .calendar-notifier-text-field {
      > div {
        border: none;
      }

      input {
        background: none;
      }
    }

    > div {
      border-right: 1px $border-color solid;
      align-items: center;
      padding: 0 10px;
      display: flex;
      height: 48px;
      margin-bottom: 12px;


      &:last-child {
        border-right: none;
      }

      .notifier-event-type [role="button"] {
        white-space: pre-wrap;
      }
    }

    > div:first-child {
      width: 23%;
      padding-left: 0;

      input {
        height: 28px;
        padding: 0;
      }
    }

    > div:last-child {
      width: 77%;
      padding-right: 0;

      > div {
        width: 100%;
      }

      div {
        font-weight: 400;
        text-transform: lowercase;
        color: #707070;
        font-size: 13px;
      }
    }
  }

  .calendar-notifier-remind-type {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    font-size: 11px;
    color: $slate;
  }

  .date-picker-autocomplete-container {
    margin-left: 15px;
    margin-right: 15px;
    border: 1px solid $lightBlueGrey;
    border-radius: 2px;
    margin-bottom: 17px;
    min-height: 40px;
    background-color: rgba(233,237,239,0.3);

    div {
      color: $slateTwo;
    }
  }
}

.reminder-row {
  display: flex;
  font-size: 14px;
  margin-bottom: 12px;
  min-height: 40px;

  .reminder-row-label {
    color: #90A4AE;
    width: 115px;
    margin-top: 14px;
  }

  .reminder-row-value {
    display: flex;
  }

  .reminder-cell-name {
    margin-top: 14px;
  }

  .reminder-input-container {
    border: 1px solid #CFD8DC;
    height: 40px;
    max-width: 325px;

    input, .reminder-date-value {
      height: 38px;
      border: none;
      padding-left: 5px;
      padding-right: 5px;
    }

    .reminder-date-value {
      display: flex;
      padding-top: 12px;
    }
  }

  .reminder-date-input {
    padding: 0;
    width: 120px;
    position: relative;
    cursor: pointer;

    input,
    input:focus {
      max-width: 100%;
      border: none;
    }

    .DayPickerInput {
      height: auto;
      max-width: 100%;
      input {
        max-width: 100%;
      }

      .datepicker-date-input-container {
        padding-left: 0;

        input {
          border: none;
        }
      }
    }

    .reminder-date-icon {
      height: 12px;
      position: absolute;
      right: 0;
      top: 12px
    }

    .DayPickerInput-OverlayWrapper {
      max-height: 200px!important;

      > div {
        max-height: 200px!important;
      }

      .DayPickerInput-Overlay {
        height: 200px!important;
      }
    }
  }

  .reminder-select {
    border: 1px solid #CFD8DC;
    height: 40px;
    padding-left: 8px;
    border-left: none;
  }

  div[class*="focused"],
  .active-input,
  input:focus,
  textarea:focus {
    border: 1px solid $hubsyncBlue;
  }

  .reminder-amount {
    width: 53px;
    text-align: center;
  }

  .reminder-unit {
    width: 75px;
  }

  .reminder-type {
    width: 72px;
  }

  .reminder-message {
    height: 72px;
    border: 1px solid #CFD8DC;
    width: 100%
  }

  .reminder-value.expand {
    flex-grow: 1;

    textarea {
      padding: 10px;
      resize: none;
      border-radius: 2px;
    }
  }

  .reminder-select-users {
    height: 40px;
    padding: 0 10px;

    > div > div {
      display: flex;
    }

    .account-chip-account_wrapper {
      margin-right: 10px;
      width: auto;
    }
  }

  .reminder-unit,
  .reminder-select-users,
  .reminder-type {
    div > div:not(.account-chip-icon_wrapper)  {
      background: none;
    }
  }

  .date-picker-date-value {
    color: #253238;
    font-size: 13px;
    display: flex;
    align-self: center;
    margin-left: 10px;
  }
}

.reminder-action-buttons {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  border-top: 1px solid #e9edef;
}

.reminder-day-picker-container {
  min-height: 220px;
  top: 1px;
  left: -60px;
}

.reminder-input-popup {
  z-index: 100000;
}

.reminder-shared-users {
  text-decoration: underline;
  cursor: pointer;
}

.reminder-item-users-list {
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  > div {
    margin-bottom: 10px;
  }

  > div:last-child {
    margin-bottom: 0;
  }
}

.reminder-alert-container {
  letter-spacing: 0.24px;
  line-height: 16px;
  font-weight: 500;
  font-size: 12px;
  
  margin-top: 10px;
  display: flex;
  align-items: center;

  div {
    margin-left: 4px;
  }

  &.error {
    color: #E8384F;

    svg {
      color: #e8384f;
    }
  }

  &.warning {
    color: #F09300;

    svg {
      color: #F09300;
    }
  }
  
}

.reminder-account-chip {
  margin-left: 7px;
}
