@import './defaults/reset';
@import './utils/mixins';
@import './defaults/fonts';
@import './defaults/variables';
@import './defaults/globals';
@import './layouts/default';
@import './components/popover';
@import './components/buttons';
@import './components/filethis';
@import './components/breadcrumbs-bar.scss';
@import './components/aggrid.scss';
@import './components/multilinetext-editor.scss';
@import './components/rich-text-editor.scss';
@import './components/multi-factor-authentication.scss';
@import './components/phone-selector.scss';


html,
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  background-color: $background-color;
  color: $dark;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}
html {
  overflow: hidden;
}

body {
  font-size: 1em;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}

/** ---- Safari fix ---- **/
input[type="search"] {
  -webkit-appearance: none;
}

.ag-root-wrapper.ag-layout-normal {
  width: 100%;
}
/** ---- Safari fix ---- **/

.error-message-container {
  color: $white;
  background-color: $tomato;
  text-align: center;
  padding: 15px 20px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.highlighted-cell {
  background-color: rgba($hubsync-blue, 0.2);
}

.modal-view-switch {
  margin-top: 15px;
}

/* Locked padding for MUI Text Field */
div.locked {
  input[type="number"], input[type="text"] {
    padding-right: 24px;
  }
}

.hb-click-barrier{
  display: block;
  position: absolute;
  margin-left: -16px;
  width: 100%;
  height: 100%;
}

.loader-icon .path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite;
  animation: dash 1.5s ease-in-out infinite;
  stroke: #5592FB;
  stroke-linecap: square;
}

div[class*='MuiBox-root jss'] {
  height: unset !important;
  width: unset !important;
  border: unset !important;
}

//#endregion
