@import "~styles/defaults/variables";

.star {
  svg {
    height: 13px;
    color: $offYellow;
    margin-right: 2px;
  }

  &:last-child {
    margin-right: 2px;
  }
}
