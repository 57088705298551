@import '../defaults/variables';

.bulk-update-dropdown {
  color: $slateTwo;
  border: 1px solid $lightBlueGrey;
  height: 40px;
  width: 100%;
  padding: 8px;
  border-radius: 2px;
  background-color: $white;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.bulk-update-dropdown-placeholder {
  height: 25px;
  padding: 0;
  font-size: 11px;
  line-height: 25px;
  letter-spacing: 0.18px;
  color: #253238;
  overflow: hidden;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: normal;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: 0.22px;
  display: flex;
  align-items: center;
}

.bulk-update-popover {
  width: 450px;
  max-height: 250px;
  > div:last-child {
    width: 450px;
  }
}
