@import 'styles/defaults/variables';

.collections__main__container.users {
  height: 100%;
  width: 100%;

  .users {
    height: 100%;
    flex: 1 1;
    display: flex;
    flex-flow: column nowrap;
    border-radius: .25rem;
    box-shadow: 0 4px 10px 0 #dee2e9;
    margin: 0 -16px;
    overflow: hidden;
  }

  .users-title {
    font-size: 1em;
  }

  .page-header-container {
    padding: 0 1rem;
  }

  .people-export{
    position: absolute;
    margin-top: 79px;
    left: 390px;
    height: 29px;
  }

  .ag-cell-value {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: $dark;
    * {
      color: $dark!important;
    }
  }

  .ag-cell[col-id='name'] {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding-top: 6px;
    padding-right: 0;
  }

  .ag-header-icon {
    display: none;
  }

  .ag-cell-last-left-pinned {
    justify-content: center;
  }

  .ag-header-cell-label {
    .ag-header-cell-text {
      margin-left: 5px;
    }

    svg {
      height: 13px;
      width: 13px;
    }
  }

  .ag-header-cell[col-id='row_number_id'],
  .row_number_id {
    padding-left: 0;
    border-left: 0;
    .ag-cell-value {
      justify-content: center;
      div {
        text-align: center;
      }
    }
  }

  .ag-header-cell[col-id='row_number_id'] {
    .ag-checkbox-input-wrapper {
      margin-left: 29px!important;
    }
  }

  .ag-selection-checkbox {
    .ag-checkbox-input-wrapper {
      margin-left: 29px!important;
    }
  }

  .ag-row-hover,
  .ag-row-selected {
    .row_number_id {
      .ag-cell-value {
        text-indent: -9999px;
      }
    }
  }

  .update-user-arrows {
    width: 20px;
    height: 20px;
    display: none;
    z-index: 9999;
    cursor: pointer;
    button {
      box-shadow: none !important;
    }
  }

  .ag-row-hover .update-user-arrows {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ag-cell-value:not(.ag-cell-focus) .dropdown-button {
    display: none;
  }

  .single-choice-item > div:not(.grid-choice-item-bullet){
    margin-top: 1px;
    letter-spacing: .2px;
  }

  .multiple-choice-item-medium > div:not(.grid-choice-item-bullet){
    margin-top: 1px;
    letter-spacing: .2px;
  }

  .multiple-choice-item-tall > div:not(.grid-choice-item-bullet){
    margin-top: 1px;
    letter-spacing: .2px;
  }


  .ag-cell-value .dropdown-button {
    margin-top: 3px;
  }

  .ag-root-wrapper svg {
    color: $slateTwo;
  }

  .people-sort-column-icon {
    margin-left: 6px;
  }

  .sort-and-filter-container {
    display: flex;
    height: 36px;
    background-color: #fff;
    padding-left: 18px;
    align-items: center;
  }

  .page-header-container > div {
    border-bottom: none;
  }
}

.resend-invitation-container {
  margin-top: 16px;

  svg{
    height: 14px;
    width: 14px;
  }
}

.no-user-update-permission {
  .update-user-arrows {
    display: none!important;
  }
}

.update-user-action-buttons {
  display: flex;
  justify-content: space-between;
  margin: 20px 30px 30px;
}

.custom-select-menu {
  ul {
    padding: 0!important;
  }
}

.custom-select-wrapper {
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
  position: relative;
  svg {
    width: 16px;
    position: absolute;
    right: 8px;
    top: 8px;
    pointer-events: none;
  }
}

.custom-select-button {
  height: 40px;
  color: #505f79;
  border: 1px solid #cfd8dc;
  padding:  8px;
  min-width: 150px;
  border-radius: 2px;
  background-color: #fff;
  margin-right: 0;
}

.custom-select-menu-item {
  height: 54px;
  padding: 0!important;
  border-bottom: 1px solid #E9EDEF;
  position: relative;
}

.custom-select-menu-item-text {
  padding: 8px 24px 8px 0;

  p {
    color: #546E7A;
    font-family: Roboto;
    font-size: 11px;
    letter-spacing: 0.22px;
    line-height: 13px;
  }

  span {
    color: #37474F;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 4px;
  }
}

.checked-role-icon {
  padding: 8px;
  width: 32px;
}

.role-update-label {
  margin-bottom: 5px;
  span {
    font-size: 12px;
    text-transform: initial;
  }
}

.modal-header {
  padding: 22px 30px;
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #e9edef;
  justify-content: space-between;
  .modal-header-card {
    display: flex;
    .modal-header-card-text {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      .modal-header-card-title {
        color: #37474F;
        font-family: Roboto;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 27px;
      }
      .modal-header-card-role {
        color: #505F79;
        font-family: Roboto;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        margin-bottom: 5px;
      }
      .modal-header-card-email {
        color: #0065FF;
        font-family: Roboto;
        font-size: 13px;
        letter-spacing: 0.2px;
        line-height: 16px;
      }
    }
  }
}

.remove-user-modal-btn {
  color: $tomato;

  &:hover {
    color: $tomato!important;
  }
}

.role-update-select {
  width: 100%;
  height: 40px;
  min-width: 120px;
  max-width: 180px;
}

.context-menu-item{
  .ag-menu-option-icon{
    .icon-container{
      display: flex;
    }
  }

  svg{
    width: 14px;
    height: 14px;
  }
}
