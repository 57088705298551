@import "~styles/defaults/variables";

.account-notification-container {
    margin-bottom: 16px;
    padding-bottom: 166px;

    .account-notification-reset {
      margin-top: 15px;
    }
}

.account-general-notification-header {
    border-bottom: 1px solid #EAEEF0;
    display: flex;
    flex-grow: 1;
    padding-bottom: 8px;
}

.account-general-notification-item {
    border-bottom: 1px solid #EAEEF0;
    height: 100%;
    align-self: center;
    display: flex;
}

.account-general-notification-item-text-container {
    padding: 8px 0;
    span {
        font-size: 14px;
        color: $title-color;
        font-family: Roboto;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 22px;
    }
}

.account-notification-title {
    color: $slateTwo;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 22px;
}

.account-general-notification-item-text-container, .account-general-notification-item-actions {
    display: flex;
    height: 100%;
    align-items: center;
}

.account-general-notification-center-content {
    text-align: center;
}

.account-general-notification-item-actions {
    .account-general-notification-item-content {
        flex: 1;
    }
}
