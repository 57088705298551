.collections__main__container.users {
  .ag-header-select-all {
    margin: auto;
  }
  .ag-selection-checkbox {
    .ag-checkbox-input-wrapper {
      margin-left: 29px !important;
    }
  }

  .ag-theme-material {
    .ag-cell[col-id='row_number_id'] {
      padding: 0 !important;
      .expand {
        display: none;
      }
    }
    
    .ag-cell {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      padding-top: 6px;
      padding-right: 0;
    }
  }

  .ag-row-hover .ag-cell[col-id='row_number_id'] {
    .row-number {
      display: none;
    }
    .expand {
      display: block;
    }
  }

  .ag-row-selected .ag-cell[col-id='row_number_id'] {
    .row-number {
      display: none;
    }
  }

  .row-number {
    display: flex;
    justify-content: space-around;
  }
  .row-number-value {
    margin: auto;
  }

  .ag-react-container > span {
    width: 100%;
  }
}
