.notification-toastify-body {
    color: #253238 !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0 !important;
    line-height: 20px !important;
    display: flex !important;
    justify-content: space-between !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.notification-toastify {
    border-radius: 0 4px 4px 0 !important;
    box-shadow: 0 1px 3px 0 rgba(76,175,80,0.2);
    padding: 16px 0 16px !important;
    height: auto !important;
    min-height: 48px !important;

    &.nt-success {
        border-left: 3.15px solid #33B679;
        background-color: #D6FFEC;
        .notification-toastify-icon{
            color: #33B679;
        }
    }
    &.nt-warn {
        border-left: 3.15px solid #F6BF26;
        background-color: #FDF5E0;
        .notification-toastify-icon{
            color: #F6BF26;
        }
    }
    &.nt-error {
        border-left: 3.15px solid #E8384F;
        background-color: #FFF0F2;
        .notification-toastify-icon{
            color: #E8384F;
        }
    }
    &.nt-info {
        border-left: 3.15px solid #0065FF;
        background-color: #EBF3FF;
        .notification-toastify-icon{
            color: #0065FF;
        }
    }
}

.notification-toastify-icon{
    text-align: center;
    width: 16px;
    margin-left: 14px;
    margin-top: 3px;
    grid-area: icon;
}

.notification-toastify-close-icon {
    width: 12px;
    margin-left: 8px;
    margin-top: 3px;
    grid-area: closeIcon;
}

.notification-toastify-wrapper {
    display: grid;
    grid-template-columns: 43px 1fr 32px;
    width: 100%;
    grid-template-areas:
        "icon message   closeIcon"
        "icon paragraph paragraph"
        "icon button    .";
}

.notification-toastify-message {
    grid-area: message;
}

.notification-toastify-paragraph {
    grid-area: paragraph;
    line-height: 18px !important;
    font-weight: 400 !important;
    margin: 12px 12px 0 0;
}

.notification-toastify-button {
    grid-area: button;
    margin-top: 12px;
    color: #0065FF !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}


