@import "~styles/defaults/variables";

.root {
  border-radius: 50%;
  padding: 0;
}

.buttonExtraSmall {
  width: 16px;
  height: 16px;
  & > svg {
    vertical-align: middle;
  }
}

.iconExtraSmall{
  height: 8px;
  & > svg {
    vertical-align: middle;
  }
}

.buttonMedium {
  width: 26px;
  height: 26px;
  & > svg {
    vertical-align: middle;
  }
}
.iconMedium {
  height: 13px;
  & > svg {
    vertical-align: middle;
  }
}

.iconBlue {
  color: $hubsyncBlue;
  &:hover {
    color: $darkBlue;
  }
}
.iconLightGray {
  color: $lightBlueGrey;
  &:hover {
    color: $slateTwo;
  }
}
.iconDarkGray {
  color: $slateTwo;
  &:hover {
    color: $hubsyncBlue;
  }
}
.selected {
  background-color: $skyBlue;
  color: $hubsyncBlue;
}
