.outage-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

body {
    background-color: #FFFFFF;
}

.outage-logo {
    width: 9.875rem;
    height: 3.898rem;
    margin-top: 3.688rem;
}

.outage-image {

    height: 236px;
    width: 288px;
}

.outage-title {
    width: 223.9px;
    height: 32px;
    left: 571.05px;
    top: 712px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    text-align: center;

    color: #212121;
    margin-top:30px;
}

.outage-subtitle {
    width: 518px;
    height: 22px;
    left: 424px;
    top: 760px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    display: flex;
    align-items: center;
    text-align: center;
    margin-top:15px;

    color: #212121;
}
