@font-face {
  font-family: 'icons';
  src:  url('../../assets/fonts/icons/icons.eot?4z3mtf');
  src:  url('../../assets/fonts/icons/icons.eot?4z3mtf#iefix') format('embedded-opentype'),
    url('../../assets/fonts/icons/icons.ttf?4z3mtf') format('truetype'),
    url('../../assets/fonts/icons/icons.woff?4z3mtf') format('woff'),
    url('../../assets/fonts/icons/icons.svg?4z3mtf#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pin2:before {
  content: "\e964";
}
.icon-pin3:before {
  content: "\e965";
}
.icon-calendar22:before {
  content: "\e951";
}
.icon-calendar3:before {
  content: "\e952";
}
.icon-calendar-empty:before {
  content: "\e953";
}
.icon-calendar-day:before {
  content: "\e954";
}
.icon-calendar-week:before {
  content: "\e955";
}
.icon-enlarge5:before {
  content: "\e956";
}
.icon-shrink5:before {
  content: "\e957";
}
.icon-grid7:before {
  content: "\e958";
}
.icon-plus2:before {
  content: "\e959";
}
.icon-minus2:before {
  content: "\e95a";
}
.icon-plus3:before {
  content: "\e95b";
}
.icon-minus3:before {
  content: "\e95c";
}
.icon-plus-circle2:before {
  content: "\e95d";
}
.icon-more2:before {
  content: "\e94d";
}
.icon-height2:before {
  content: "\e94e";
}
.icon-bin:before {
  content: "\e945";
}
.icon-bin3:before {
  content: "\e946";
}
.icon-bin4:before {
  content: "\e947";
}
.icon-menu:before {
  content: "\e948";
}
.icon-seven-segment-8:before {
  content: "\e949";
}
.icon-990:before {
  content: "\e906";
}
.icon-1040:before {
  content: "\e914";
}
.icon-angle-down:before {
  content: "\e915";
}
.icon-angle-left:before {
  content: "\e916";
}
.icon-angle-right:before {
  content: "\e917";
}
.icon-angle-up:before {
  content: "\e918";
}
.icon-bell:before {
  content: "\e919";
}
.icon-briefcase:before {
  content: "\e91a";
}
.icon-uniE91D:before {
  content: "\e91d";
}
.icon-chart-bar:before {
  content: "\e91e";
}
.icon-pie-1:before {
  content: "\e91f";
}
.icon-pie-2:before {
  content: "\e920";
}
.icon-cloud-arrow:before {
  content: "\e921";
}
.icon-cog:before {
  content: "\e922";
}
.icon-chat-1:before {
  content: "\e923";
}
.icon-chat-2:before {
  content: "\e924";
}
.icon-exclamation-circle:before {
  content: "\e925";
}
.icon-exclamation-point:before {
  content: "\e926";
}
.icon-exclamation-triangle:before {
  content: "\e927";
}
.icon-files-2:before {
  content: "\e92a";
}
.icon-files-1:before {
  content: "\e92c";
}
.icon-folder-1:before {
  content: "\e92d";
}
.icon-folder-2:before {
  content: "\e92f";
}
.icon-house:before {
  content: "\e935";
}
.icon-check:before {
  content: "\e900";
}
.icon-flip:before {
  content: "\e901";
}
.icon-funnel:before {
  content: "\e902";
}
.icon-globe:before {
  content: "\e903";
}
.icon-graph:before {
  content: "\e904";
}
.icon-handshake:before {
  content: "\e905";
}
.icon-k1:before {
  content: "\e907";
}
.icon-list-1:before {
  content: "\e908";
}
.icon-list-2:before {
  content: "\e909";
}
.icon-magnifying-glass:before {
  content: "\e90a";
}
.icon-pdf:before {
  content: "\e90b";
}
.icon-person-o:before {
  content: "\e90c";
}
.icon-person:before {
  content: "\e90d";
}
.icon-picture:before {
  content: "\e90e";
}
.icon-power:before {
  content: "\e90f";
}
.icon-squares:before {
  content: "\e910";
}
.icon-wheel:before {
  content: "\e911";
}
.icon-word:before {
  content: "\e912";
}
.icon-x:before {
  content: "\e913";
}
.icon-table:before {
  content: "\e95e";
}
.icon-wysiwyg:before {
  content: "\e95e";
}
.icon-table2:before {
  content: "\e95f";
}
.icon-wysiwyg2:before {
  content: "\e95f";
}
.icon-calendar2:before {
  content: "\ea5f";
}
.icon-price-tag22:before {
  content: "\e9ee";
}
.icon-price-tag32:before {
  content: "\e9f0";
}
.icon-search2:before {
  content: "\eb30";
}
.icon-checkbox-checked2:before {
  content: "\ee66";
}
.icon-envelop32:before {
  content: "\ea32";
}
.icon-phone22:before {
  content: "\ea1d";
}
.icon-circle-half:before {
  content: "\e93e";
}
.icon-circle-full:before {
  content: "\e93f";
}
.icon-hash:before {
  content: "\e93c";
}
.icon-target:before {
  content: "\e93d";
}
.icon-type:before {
  content: "\e93a";
}
.icon-typography:before {
  content: "\e93a";
}
.icon-text:before {
  content: "\e93a";
}
.icon-font:before {
  content: "\e93a";
}
.icon-text-color:before {
  content: "\e93b";
}
.icon-wysiwyg3:before {
  content: "\e93b";
}
.icon-price-tag3:before {
  content: "\e9f1";
}
.icon-phone2:before {
  content: "\ea1e";
}
.icon-envelop3:before {
  content: "\ea33";
}
.icon-calendar:before {
  content: "\ea60";
}
.icon-search:before {
  content: "\eb31";
}
.icon-checkbox-checked:before {
  content: "\ee67";
}
.icon-barcode:before {
  content: "\e9f6";
}
.icon-percent:before {
  content: "\ea09";
}
.icon-coin-dollar:before {
  content: "\ea0b";
}
.icon-link:before {
  content: "\ec99";
}
.icon-attachment:before {
  content: "\ecac";
}
.icon-star-full:before {
  content: "\ece2";
}
.icon-check:before {
  content: "\e900";
}
.icon-flip:before {
  content: "\e901";
}
.icon-funnel:before {
  content: "\e902";
}
.icon-globe:before {
  content: "\e903";
}
.icon-graph:before {
  content: "\e904";
}
.icon-handshake:before {
  content: "\e905";
}
.icon-k1:before {
  content: "\e907";
}
.icon-list-1:before {
  content: "\e908";
}
.icon-list-2:before {
  content: "\e909";
}
.icon-magnifying-glass:before {
  content: "\e90a";
}
.icon-pdf:before {
  content: "\e90b";
}
.icon-person-o:before {
  content: "\e90c";
}
.icon-person:before {
  content: "\e90d";
}
.icon-picture:before {
  content: "\e90e";
}
.icon-power:before {
  content: "\e90f";
}
.icon-squares:before {
  content: "\e910";
}
.icon-wheel:before {
  content: "\e911";
}
.icon-word:before {
  content: "\e912";
}
.icon-x:before {
  content: "\e913";
}
.icon-table:before {
  content: "\e95e";
}
.icon-wysiwyg:before {
  content: "\e95e";
}
.icon-table2:before {
  content: "\e95f";
}
.icon-wysiwyg2:before {
  content: "\e95f";
}
.icon-calendar2:before {
  content: "\ea5f";
}
.icon-price-tag22:before {
  content: "\e9ee";
}
.icon-price-tag32:before {
  content: "\e9f0";
}
.icon-search2:before {
  content: "\eb30";
}
.icon-checkbox-checked2:before {
  content: "\ee66";
}
.icon-envelop32:before {
  content: "\ea32";
}
.icon-phone22:before {
  content: "\ea1d";
}
.icon-circle-half:before {
  content: "\e93e";
}
.icon-circle-full:before {
  content: "\e93f";
}
.icon-hash:before {
  content: "\e93c";
}
.icon-target:before {
  content: "\e93d";
}
.icon-type:before {
  content: "\e93a";
}
.icon-typography:before {
  content: "\e93a";
}
.icon-text:before {
  content: "\e93a";
}
.icon-font:before {
  content: "\e93a";
}
.icon-text-color:before {
  content: "\e93b";
}
.icon-wysiwyg3:before {
  content: "\e93b";
}
.icon-price-tag3:before {
  content: "\e9f1";
}
.icon-phone2:before {
  content: "\ea1e";
}
.icon-view-column:before {
  content: "\e960";
}
.icon-view-carousel:before {
  content: "\e961";
}
.icon-view-show:before {
  content: "\e938";
}
.icon-view-hide:before {
  content: "\e939";
}
.icon-arrow-thick-up:before {
  content: "\e91b";
}
.icon-arrow-thick-right:before {
  content: "\e928";
}
.icon-arrow-thick-left:before {
  content: "\e929";
}
.icon-arrow-thick-down:before {
  content: "\e92b";
}
.icon-arrow-outline-up:before {
  content: "\e92e";
}
.icon-arrow-outline-right:before {
  content: "\e930";
}
.icon-arrow-outline-left:before {
  content: "\e931";
}
.icon-arrow-outline-down:before {
  content: "\e932";
}
.icon-arrow-right:before {
  content: "\e933";
}
.icon-arrow-left:before {
  content: "\e934";
}
.icon-arrow-down:before {
  content: "\e936";
}
.icon-arrow-up:before {
  content: "\e937";
}
.icon-arrow-thick-down:before {
  content: "\e92b";
}
.icon-arrow-outline-up:before {
  content: "\e92e";
}
.icon-arrow-outline-right:before {
  content: "\e930";
}
.icon-arrow-outline-left:before {
  content: "\e931";
}
.icon-arrow-outline-down:before {
  content: "\e932";
}
.icon-arrow-right:before {
  content: "\e933";
}
.icon-arrow-left:before {
  content: "\e934";
}
.icon-arrow-down:before {
  content: "\e936";
}
.icon-arrow-up:before {
  content: "\e937";
}
.icon-plus-circle:before {
  content: "\e950";
}
.icon-th-menu:before {
  content: "\e94c";
}
.icon-plus-circle:before {
  content: "\e950";
}
.icon-th-menu:before {
  content: "\e94c";
}
.icon-minus:before {
  content: "\e94f";
}
.icon-equals:before {
  content: "\e94b";
}
.icon-arrow-maximise:before {
  content: "\e943";
}
.icon-arrow-minimise:before {
  content: "\e944";
}
.icon-export:before {
  content: "\e942";
}
.icon-import_export:before {
  content: "\e941";
}
.icon-view-hide:before {
  content: "\e939";
}
.icon-arrow-thick-up:before {
  content: "\e91b";
}
.icon-arrow-thick-right:before {
  content: "\e928";
}
.icon-arrow-thick-left:before {
  content: "\e929";
}
.icon-arrow-thick-down:before {
  content: "\e92b";
}
.icon-arrow-outline-up:before {
  content: "\e92e";
}
.icon-arrow-outline-right:before {
  content: "\e930";
}
.icon-arrow-outline-left:before {
  content: "\e931";
}
.icon-ellipsis-v:before {
  content: "\e91c";
}
.icon-ellipsis-h:before {
  content: "\e94a";
}
.icon-sort:before {
  content: "\e940";
}
.icon-unsorted:before {
  content: "\e940";
}
