@import 'styles/defaults/variables';

.import-database-error-container {
    display: flex;
    justify-content: center;
    align-self: center;
}

.import-database-filename-container {
    display: flex;
    align-items: center;
    .import-database-title {
        font-size: 14px;
        color: $brownishGrey;
        margin-left: 10px;
    }
}

.import-database-delete-message {
    font-size: 14px;;
    color: $brownishGrey;
}

.import-field-url {
    text-decoration: underline;
}

.ag-row-focus {
    .import-field-url {
        color: #2d7ff9;
    }
}

.import-choice-field {
    > div > div,
    > div > span {
        display: none;
    }
    > div > div:first-child {
        display: flex;
    }
}

.import-database-grid__wrapper {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.import-database-grid {
    flex: 1 1;
    display: flex;
    flex-flow: column nowrap;
    border-radius: .25rem;
    box-shadow: 0 4px 10px 0 #dee2e9;
    margin: 0 -16px;

    .import-field-choice-bullet {
        width: 9px;
        height: 9px;
        border-radius: 10px;
        flex-shrink: 0;
        background-color: lightGrey;
        margin-right: 6px;
    }

    .ag-pinned-left-cols-container {
        .ag-cell {
            justify-content: center;
        }
    }

    .ag-header-cell-label svg {
        height: 13px;
        width: 13px;
    }

    .ag-header-cell-text {
        margin-left: 5px;
    }

    .ag-row .ag-cell {
        display: flex;
        align-items: center;
    }

    .ag-header-cell {
        border-bottom: 1px solid #e2e2e2!important;
    }

    .ag-menu-option {
        font-size: 0.8125rem;
        height: 1.8125rem;
        color: #172b4d;
        cursor: pointer;
        padding: 0 0 0 0.3rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 29px!important;
        .ag-menu-option-icon {
            text-align: center;
        }
    }

    .ag-header-icon {
        cursor: pointer;
        opacity: 1!important;
        width: 14px;
        height: 14px;
        mask: url(../../icons/dropdown.svg) no-repeat 100% 100%;
        mask-size: cover;
        background-color: $text-darkblue;
        .ag-icon-menu {
            &::before {
                content: '';
            }
        }
    }

    .import-choice-container {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
}

.import-database-modify-field-container {
    width: 300px;
    max-height: 320px;
    background-color: #fff;
    border-radius: 4px;
    padding: 8px 10px 11px;
    box-shadow: 0 8px 11px -5px rgba(0,0,0,0.14), 0 5px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -5px rgba(0,0,0,0.2);
    overflow: inherit;

}
