.scrollarea.hubsync-scrollarea {
  z-index: 0;
  &:hover {
    .scrollbar-container, .scrollbar-container.vertical {
      opacity: 1;
    }
  }

  .scrollbar-container, .scrollbar-container.vertical {
    width: 4px;
    background-color: #e9edef;
    opacity: 0.7;
    margin-right: 0;

    .scrollbar {
      width: 4px;
      background-color: #90a4ae;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
}

::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-width: 10px;
}

::-webkit-scrollbar-thumb:horizontal:active {
  background: rgba(0, 0, 0, 0.61);
  border-radius: 100px;
}
