.main__container {
  max-width: $max-width;
  margin: 0 auto;
  padding: $default-padding;

  &:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
}

.main__header {
  padding: 2.5rem 0;
}

.main__title {
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
