@import "scrollbar";

.clear {
  &:after {
    display: block;
    width: 100%;
    clear: both;
  }
}

.bold {
  font-weight: bold;
}

button {
  border: none;
  cursor: pointer;
  background-color: inherit;
}

a {
  color: $title-color;
}

.hide {
  display: none;
}

.center {
  text-align: center;
}

.rbc-overlay {
  .rbc-event {
    background-color: #fff;
  }
}

.hubsync-kanban-view {
  .hubsync-content {
    overflow: hidden;
  }
}

.hubsync-menu-searchbar {
  width: auto;
  margin: 10px 15px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: $lightBlueGrey;
  background-color: $greyTransparent;
}

.modify-choice-field {
  > div > div,
  > div > span {
    display: none;
  }
  > div > div:first-child {
    display: flex;
  }
}

.fillWidth {
  width: 100%;
}

.text-disable {
  color:#505F79;
}
