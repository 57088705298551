.number-editor {
    border:1px solid #CFD8DC;
    background-color: #F8FAFA;
    padding-right: 10px;
    padding-left: 10px;
}

.number-editor-wrapper {
    height: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 15px;
    color: #3c4144;
    display: block;
}



